import * as React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Layout from '../../components/layout';
import '../../styles/management.scss';

const TerriWest = () => {
  return (
    <Layout>
      <SEO
        title="TERRI WEST - Management | Ostara"
        description="NUTRIENT MANAGEMENT SOLUTIONS"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main className="management">
        <Container
          className="management-details-container"
          w="90%"
          paddingBottom={30}
          maxW={1694}
        >
          <Box className="manager-details">
            <div className="manager-details__text">
              <Link className="manager-details__back" to="/management">
                <ArrowBackIcon color="#044606" />
                BACK
              </Link>
              <StaticImage
                className="manager-details__img mobile"
                placeholder="transparent"
                src="../../images/terri-west.jpg"
                alt=""
              />
              <Heading
                as="h2"
                color="#044606"
                className="manager-details__name"
              >
                Terri West,
                <br />
                CPA, MBA
              </Heading>
              <Text color="#044606" className="manager-details__position">
                Chief Financial Officer
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Ms. West has over 15 years of experience leading finance and
                accounting organizations. She is responsible for all of Ostara’s
                financial functions, including accounting, audit, treasury,
                corporate finance and investor relations.
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Prior to joining Ostara, Ms. West served as Chief Financial
                Officer for Actagro, a soil and plant health specialty inputs
                business, successfully leading the company through exceptional
                growth resulting in a sale of the company to a private equity
                investor in 2011; and a subsequent sale to a strategic buyer in
                2019.
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Ms. West is currently an outside director on two boards, serving
                as audit committee chair for an agriculture cooperative lending
                institution and chairperson for a large privately-held vegetable
                farming, cooling, and distribution business.
              </Text>
              <Text color="#044606" className="manager-details__bio">
                Ms. West has a B.B.A. in Marketing from Pacific Lutheran
                University and a Master’s Degree in Business Administration
                (Finance) from California State University, Fresno. She is also
                a licensed Certified Public Accountant in the state of
                Washington.
              </Text>
            </div>
            <StaticImage
              className="manager-details__img desktop"
              placeholder="transparent"
              src="../../images/terri-west.jpg"
              alt=""
            />
          </Box>
        </Container>

        <Container className="body-cta-container" maxW={1400}>
          <Box>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default TerriWest;
